/* add css module styles here (optional) */

.test {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}
/* 
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700'); */
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
@font-face{
  font-family: C-black;
  src: url('../src/assets/fonts/CircularStd-Black.ttf');
}
@font-face{
  font-family: C-bold;
  src: url('../src/assets/fonts/CircularStd-Bold.ttf');
}
@font-face{
  font-family: C-book;
  src: url('../src/assets/fonts/CircularStd-Book.ttf');
}
@font-face{
  font-family: C-medium;
  src: url('../src/assets/fonts/CircularStd-Medium.ttf');
}

h1, h2{
  font-family:C-black;
}
h3, h4, h5{
  font-family:C-bold;
}
h6{
  font-family:C-Medium;
}
p, a{
  font-family: C-book;
}

.btn-grey{
  background-color:#efefef;
  font-family: C-black;
  color: #1B0512;
  font-size: 12px;
  padding: 10px 10px;
  border-radius: 0 !important;
  width: 50%;
}
.btn-grey:hover, .btn-grey.active{
  background-color:#1B0512;
  color: #fff;
}

.hide-lg{
  display: none;
}
.show-lg{
  display:block;
}

body {
  background: transparent;
  width: 100%;
  height: 100vh;
  /* font-family: 'Raleway', sans-serif; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'Inter', sans-serif;
  /* letter-spacing: 2px; */
  font-size: 0.8rem;
  background: radial-gradient(rgba(194, 211,207, 0.8),rgba(161, 161,203, 0.8)20%, rgba(217, 211, 215,0.8) 100%) ;
  /* background: rgba(0, 0, 0, 0.5); */
}

.left-sidebar{
  width:100%;
  border-bottom:1px dashed #98027f;
}

.left-sidebar-header{
  padding: 25px;
}
 .list-group{
  max-height: 320px;
  overflow-y:scroll;
}
 .list-group::-webkit-scrollbar{
  width: 5px;
}
.list-group::-webkit-scrollbar-thumb{
  width: 10px;
  background-color: #e9bde1;
}
.left-sidebar-list-item{
  padding:15px;
  width:100%;
}

li{
  list-style: none;
  padding:10px;
}

.radio-label{
  padding-left:15px;
}

.error-text{
  color:red;
  text-align:'center';
  font-size:0.7rem;
  text-shadow: 0.1px 0.3px rgb(238, 132, 132);
}

.green-text{
  color:green;
}

.header{
  margin-bottom: 20px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.0em + 1.0rem + 4px);
    padding: .575rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    background-color: transparent;
    border: 1.3px solid #78757c;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  /* background-color: #029875;
  border-color: #029875; */
  background-color: #98027f;
  border-color: #98027f;
}
.list-group-item{
  /* color: #a7038b !important; */
  /* border-color: #dbd4da !important; */
  display: flex;
  padding-top:15px;
  padding-bottom:15px;
  justify-content: space-between;
  align-items:center;
}
/* .list-group-item:hover{
  background-color: #a7038b !important;
  color: #fff !important;
} */

.no-gutters {
  padding-left: 0;
  padding-right: 0;
  background: #e9bde1;
  border-radius: 10px;
}

.heading-3 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-top: -18px;

}

.error-alert{
  border-bottom: 2px solid #FF8B00;
}

.app-container {
  margin: 10% auto;
  /* width: 635px;
  height: 430px; */
  background: white;
  /* box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2); */
  /* border-radius: 10px; */
  
  border-radius:5px;
  overflow: hidden;
  padding: 5px;
  border: 1px solid #b39eab;
  height:500px;
}.app-container .card-body{
  position: relative;
  z-index: 2;
}
.app-container .card-body::before{
  position: absolute;
  content: '';
  width: 60%;
  height: 60%;
  background: url('assets/white-pattern.png');
  /* background-color: #861b59; */
  bottom: -10%;
  right: -5%;
  z-index: -1 !important;
}
.app-container .btn-close{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin: 5px 0px;
  color: #000;
  position: absolute;
  top: 0;
  right:20%;
  background-color:#fff;
  cursor: pointer;
}

.pd-t-20{
  padding-top:15px;
}

.pd-t-15{
  padding-top:15px;
}

.pd-b-15{
  padding-bottom:10px;
}

.item-container .item-image img {
  height: 430px;
  width: 100%;
}

.item-image {
  position: absolute;
  z-index: 1;
}

.item-details {
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  margin-left: 20px;
}

.item-name {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.item-price {
  font-size: 25px;
  font-weight: 700;
  color: #0b8a6d;
}

.checkout {
  /* height: 430px; */
  height: 100%;
  /* background: #2f2c33; */

  /* background: #eee; */
  /* background: #25011f; */
  /* color: white; */
  color: black;
  border-radius:0px 10px 0px 0px;
}

.checkout-container {
  padding: 15px 5px;
}

.input {
  padding: 5px 0;
}

.cornered {
  background: repeating-linear-gradient(0 deg, #000, #000 25%, transparent 25%, transparent 75%, #000 75%),
    repeating-linear-gradient(90 deg, #000, #000 25%, transparent 25%, transparent 75%, #000 75%),
    repeating-linear-gradient(180 deg, #000, #000 25%, transparent 25%, transparent 75%, #000 75%),
    repeating-linear-gradient(270 deg, #000, #000 25%, transparent 25%, transparent 75%, #000 75%);
  background-size: 10px 100%, 10px 100%, 10px 100%, 100% 10px;
  background-position:0 0, 0 0, 100% 0, 0 100%;
  background-repeat:no-repeat;
  /* padding:20px; */
  width:300px;
  height:300px;
  margin: auto;
  padding-top: 0px;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

}

.camera-image-section{
  align-items: center;
  text-align: center;
}

.camera-image-section-front{
  height:60vh;
}


.camera-step-description{
  font-size: 18px !important;
  text-align: center;
}

.input label {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #78757c;
}

label{
  font-size: 15px !important;
}

.input input {
  width: 100%;
  padding: 5px 10px;
  color: white;
  letter-spacing: 0px;
  font-size: 14px;
  background: 0;
}

.input input:focus {
  outline: 0;
}

.input-field {
  border: 1px solid #78757c;
  border-radius: 5px;
  position: relative;
}

.input-field img {
  width: 25px;
  height: auto;
  position: absolute;
  right: 0;
  top: 5px;
  margin-right: 5px;
}

/* Removes form Controls  */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=month]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

input[type=month]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
}

.checkout-btn {
  /* background: linear-gradient(0deg, #019875, #029875); */
  /* background: linear-gradient(0deg, #98027f, #aa028e); */
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin-top: 50px;
  color: white;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 1.5px;
  cursor: pointer;
}
.country-proceed-btn{
    /* background: linear-gradient(0deg, #019875, #029875); */
    /* background: linear-gradient(0deg, #98027f, #aa028e); */
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    border: 0;
    border-radius: 5px;
    width: 70%;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0px 2px rgba(0, 0, 0, 0.2);
    letter-spacing: 1.5px;
    cursor: pointer;
}
.footer{
  position: absolute;
  bottom: 0;
  margin-left: 15px;
  color:black;
  font-weight:600;
}

.footer-link{
  /* color: #98027f; */
  /* color:#029875; */
  font-weight:600;
  text-decoration: none;
}





.card{
  background: #fffeff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-left:5px solid purple
}

.header-area{
  position: relative;
}
.close-btn{
  border-radius: 50%;
  font-size:20px ;
  /* background: #eccee7; */
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* right: -3%;
  top: -5%; */
  right: 2%;
  top: 2%;
  z-index: 2;
}


.info{
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.info span{
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-info i{
  font-size: 100px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #a4dac8;
  color: #23a575;
  display: flex;
  align-items: center;
  justify-content: center;
}

.failed-info i{
  font-size: 100px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #f3bcab;
  color: #e41717;
  display: flex;
  align-items: center;
  justify-content: center;
}


.next{
  /* background-color: #aa028e !important; */
  padding: 0.4rem 0.7rem !important;
  /* border-color:#834478 !important; */
}

.next:hover{
 text-decoration: none;
 color:white;
}

.previous:hover{
  text-decoration: none;
  color:black;
 }

.previous{
  background-color: transparent;
  /* color:white;
  border-color:white; */
  /* color:#7a0266 ;
  border-color:#7a0266 !important; */
  padding-top: 0px;
  padding: 0.4rem 0.7rem !important;
}

.label{
  color:greenyellow;
}

.step-content{
  margin-top:60px;
  padding: 0rem !important;
  text-align: center;
  padding: 15px;
}
 
.step-wrapper{
  padding: 0rem !important;
}

.pd-15{
  padding:15px;
}

.back-icon{
  color:black;
  margin:0px;
  padding:0px;
  padding-right:10px;
}

.webcam-class{
  width: 100%;
  height:100%;
  border-radius: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.webcam-class-front{
  width: 100%;
  height:100%;
  margin-top: 30px;
  object-fit: cover;
}


.camera-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* margin-left:20px; */
}




/* ._1Lo2h{
  border-color: red !important;
}
._2kL0S{
  background-color: rgb(250, 193, 238) !important;
  color: #7a0266 !important;
} */
.checkout input {
  color:black !important
}
.checkout input[type="radio"]:checked {
  color: #7a0266 !important;
}
.btn .btn-white{
  background-color: red !important;
  box-shadow:5px 5px 20px #ddd;
  color:black;
}



.btn-purple{
  background-color:#70174a;
  font-family: C-book;
  color: #fff;
  font-size: 15px;
}
.btn-purple:hover{
  background-color:#70174a;
  font-family: C-book;
  color: #fff;
  font-size: 15px;
}
/* .btn-purple{
  background-color: #a7038b !important;
  color: #fff !important;
  font-size: small !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
} */
.btn-purple-outline{
  /* color: #a7038b !important;
  border: 0.7px solid #a7038b !important; */
  font-size: small !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  text-align: left !important;
}

.product-wrapper{
  background-color: white;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .2);
  min-height: 100%;
  border-radius: 20px;
  margin: 2.5% auto;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
/* .product-wrapper::before{
  position: absolute;
  content: "";
  width: 300%;
  height: 100%; */
  /* background: linear-gradient(rgb(167, 62, 128), #a7038b, #72025f, #5a014b); */
  /* background: rgb(254,232,246); */
  /* transform: rotate(-30deg);
  transform-origin: top left;
  top: 0;
  left:-50% ;
} */
.product-wrapper .card{
  border:none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .2);
  border-radius: 20px;
  width: 100% ;
}
.product-wrapper .card .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-wrapper .card .logo{
  width: 40px;
}
.product-wrapper .card .back-btn i{
  font-size: 25px;
  font-weight: bold;
  /* color: #a7038b; */
}

/* blue theme starts here */



/* blue theme ends here */


.back-btn{
  background: #FFFFFF;
box-shadow: 0px 10px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
border-radius: 24px;

}

.single-carousel{
  padding:40px;
  padding-top: 0px;
}

.single-carousel-img{
  width: 60px;
}

.intro-text-section{
  text-align: center;
}
.intro-text-header{
  text-align: center;
  font-size: 21px !important;
}
.intro-text-body{
  text-align: center;
  font-size: 14px  !important;
}

.carousel-footer{
  text-align: center;
}

/* ID checker widget page starts here */
.id-checker-widget-area .preview-area .card{
  border-radius:5px;
  overflow: hidden;
  padding: 5px;
  border: 1px solid #b39eab;
  min-height:570px;
}
.id-checker-widget-area .preview-area .card-body{
  position: relative;
  z-index: 2;
}
.id-checker-widget-area .preview-area .card-body::before{
  position: absolute;
  content: '';
  width: 60%;
  height: 60%;
  background: url('assets/white-pattern.png');
  /* background-color: #861b59; */
  bottom: -10%;
  right: -5%;
  z-index: -1 !important;
}
/* {
  display: flex;
  justify-content:flex-end;
  margin: 5px 0px;
  font-size: 30px;
} */
.id-checker-widget-area .preview-area .close-btn{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin: 5px 0px;
  color: #000;
  position: absolute;
  top: 0;
  right:-10%;
  background-color:#fff;
  cursor: pointer;
}
.id-checker-widget-area .preview-area ul.list-group{
  height: 255px !important;
  overflow-y: scroll;
}
.id-checker-widget-area .preview-area ul.list-group li{
  /* border:1px solid grey; */
  /* border-radius:2px; */
  padding: 10px 5px;
  font:16px C-book !important;
  margin-right: 10px;
}
.id-checker-widget-area .preview-area fieldset, .id-checker-widget-area .preview-area input, .id-checker-widget-area .preview-area select{
  background-color: transparent;
  color: #081630 !important;
}
/* .id-checker-widget-area .preview-area legend{
  color: #fff;
} */
.id-checker-widget-area .preview-area .theme-purple{
  /* background-color: #70174a; */
  background: #861b59 ;
  color:#fff
}
.id-checker-widget-area .preview-area small{
  font-size: 13px;
  margin: 0 auto;
  font-family: C-book;
}
.id-checker-widget-area .preview-area p{
  font-size: 12px;
}
.id-checker-widget-area .preview-area .btn-white{
  font-size: 16px;
  padding: 15px 2px;
}
.id-checker-widget-area .progress-area{
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background-color: #abf5d1;
}
.id-checker-widget-area .progress-area .half-completed{
  width: 50%;
  height: 5px;
  border-radius: 10px;
  background-color: #006644;
}
.id-checker-widget-area .progress-area .fully-completed{
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background-color: #006644;
}

.upload-div{
  background-color: #78757c;
  width: 100%;
  border-radius: 5px;
}
.upload-img-btn {
  color: transparent;
}
.upload-img-btn::-webkit-file-upload-button {
  visibility: hidden !important;
}
.upload-img-btn::before {
  content: 'Click to upload image';
  color: #081630;
  display: inline-block;
  /* background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3); */
  background: transparent;

  /* border-bottom: 2px solid #081630; */
  /* width: 100%; */
  /* border-radius: 3px; */
  padding: 20px 20px 20px 60px;
  /* left:300px !important; */
  
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font:12px C-black;
}
.btn-transparent {
  color: #081630;
  /* display: inline-block; */
  background: transparent;
  /* display: flex;
  flex-direction: column;
  justify-content: center !important; */

  /* border-bottom: 2px solid #081630; */
  /* width: 100%; */
  /* border-radius: 3px; */
  /* padding: 20px 20px 20px 60px; */
  /* left:300px !important; */
  
  /* outline: none;
  white-space: nowrap;
  -webkit-user-select: none; */
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font:12px C-black;
}
/* .upload-img-btn:hover::before {
  border-color: #081630;
} */
.upload-img-btn:active {
  outline: 0;
}
.upload-img-btn:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
.id-checker-widget-area .scan-img{
  position:relative;
}
.id-checker-widget-area .scan-img .scan-line{
  position:absolute;
  width: 90%;
  left: 5%;
  height:3px;
  top:40%;
  box-shadow: 0px 10px 10px #000;
  /* background-color:#70174a ; */
}
.id-checker-widget-area .scan-img img{
  width: 90% !important;
}
.id-checker-widget-area .preview-area .widget-footer{
  position: absolute;
  /* position: relative; */
  text-align: center;
  /* width: 92%;
  bottom: 10px; */
  width: 90%;
  left:5%;
  bottom: 10px;
}


form fieldset{
  border: 2px solid #8e93a7 !important;
  margin: 20px auto;
  height: 100% !important;
}
legend{
  float: none;
  margin: 0 !important;
  padding:0px 5px !important;
  width: unset !important;
  font-family: C-medium;
  font-size: 14px;
  color: #344663;
}
form input, select{
  /* border: none !important; */
  font: 14px C-medium;
  padding-bottom: 10px;
  background-color: transparent;
}
form select option{
  font:13px C-book;
}
form .css-yk16xz-control{
  border: none !important;
  font-family: C-medium;
  background-color: transparent;
}
form .css-2b097c-container{
  width:100% !important;
}
form input::placeholder{
  color: #a3a2a2;
}
form input:focus, form select:focus{
  outline: none !important;
}
form .btn{
  padding: 15px 2px !important;
  font-size: 18px;
}
form textarea, form textarea:focus{
  border: none;
  outline: none !important;
}
form textarea::placeholder{
  font-family: C-black;
  color: #000;
  font-size: 14px;
}
.ReactFlagsSelect-module_selectBtn__19wW7{
  border:none !important;
  margin: 0 !important;
  padding: 0 !important;
  font: 15px C-medium !important;
  color: #344663 !important;
}
.ReactFlagsSelect-module_selectOptionValue__vS99-{
  color: #344663 !important;
}
.ReactFlagsSelect-module_label__27pw9, .ReactFlagsSelect-module_secondaryLabel__37t1D{
  color: #344663 !important;
  font: 15px C-medium !important;
}

iframe{
  z-index: unset !important;
}

/* ID checker widget page ends here */


/* Responsiveness */
@media screen and (max-width:1000px) {
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(rgba(194, 211,207, 0.8),rgba(161, 161,203, 0.8)20%, rgba(217, 211, 215,0.8) 100%) 
  }
  .app-container {
    width: 100vw;
  }
  .id-checker-widget-area {
    width: 100vw;
  }

}
@media screen and (max-width:600px) {
  body {
    display: block;
  }
  .hide-sm{
    display: none;
  }
  .show-sm{
    display:block;
  }
  .app-container {
    margin: 8% auto;
    /* width: 95%; */
    width: 95vw;
    background: white;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  /* .left-sidebar-header{
    padding: 15px 0px;
  } */
  .id-checker-widget-area .preview-area .card {
    min-height: 100vh ;
  }
  .id-checker-widget-area .preview-area .card-body .card-body{
    padding:10px 5px !important
  }
  /* .id-checker-widget-area .preview-area .card small {
    bottom: -100px;
  } */
  .left-sidebar-list-item{
    margin-left:0px;
    padding:15px;
    width:95%;
  
  }
  .checkout {
    /* height: 430px; */
    height: 100%;
  }
  .checkout-container {
    padding: 20px 0px;
    justify-content: space-evenly;
  }
  .close-btn{
    right: 7% !important;
    top: 2% !important;
    z-index:10
  }
  .footer{
    margin-left: 0px;
  }
  
}